import React, {useEffect} from "react";
import { Logo } from "../../components/Logo";
import "./style.css";

export const Element = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = `top left`;
  });
  return (
    <div className="element">
      <div className="div">
        <div className="overlap">
          <div className="frame">
            <div className="overlap-group-wrapper">
              <div className="overlap-group">
                <div className="frame-2">
                  <div className="frame-3">
                    <p className="text-wrapper">The market is for everyone!</p>
                    <img
                      className="so-why-only-few-make"
                      alt="So why only few make"
                      src="/img/so-why-only-few-make-profits-consistently.png"
                    />
                  </div>
                  <button className="CTA">
                    <div className="text-wrapper-2">Tell me why?</div>
                  </button>
                </div>
                <div className="header">
                  <Logo
                    className="logo-instance"
                    color="white"
                    colorWhiteSize="/img/logo-4.svg"
                    size="default"
                    type="logotype"
                  />
                </div>
              </div>
            </div>
            <div className="overlap-wrapper">
              <div className="overlap-2">
                <div className="frame-4">
                  <img
                    className="this-takes-years-to"
                    alt="This takes years to"
                    src="/img/this-takes-years-to-develop-the-right-mindset-even-after-develo.png"
                  />
                  <div className="div-wrapper">
                    <div className="text-wrapper-3">Show me how</div>
                  </div>
                </div>
                <div className="overlap-3">
                  <img className="vector" alt="Vector" src="/img/vector-148.svg" />
                  <div className="rectangle" />
                  <img className="group" alt="Group" src="/img/group-1969.svg" />
                  <div className="efficient-investment">Efficient Investment&nbsp;&nbsp;Entries &amp; Exits</div>
                </div>
                <div className="logo-wrapper">
                  <Logo
                    className="design-component-instance-node"
                    color="white"
                    colorWhiteSize="/img/logo-3.svg"
                    size="default"
                    type="logotype"
                  />
                </div>
                <p className="to-be-a-consistently">
                  To be a consistently <br />
                  profitable investor, you’ll need
                </p>
                <div className="overlap-4">
                  <div className="text-wrapper-4">Identifying right Stocks</div>
                </div>
                <div className="overlap-group-wrapper-2">
                  <div className="overlap-group-2">
                    <div className="rectangle-2" />
                    <div className="unwavering-trading">
                      Unwavering trading
                      <br />
                      psychology
                    </div>
                    <img className="mask-group" alt="Mask group" src="/img/mask-group-3.svg" />
                  </div>
                </div>
                <img className="img" alt="Element" src="/img/01.png" />
                <div className="div-2">
                  <div className="overlap-5">
                    <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-190.svg" />
                    <div className="text-wrapper-5">Firm Analytical Skill</div>
                    <img className="mask-group-2" alt="Mask group" src="/img/mask-group-2.svg" />
                    <img className="group-2" alt="Group" src="/img/group-1848.png" />
                  </div>
                  <img className="group-3" alt="Group" src="/img/lines-front.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="seamlessly">
            Seamlessly navigating
            <br />
            to financial goals
          </div>
          <p className="growth-user-centric">
            Growth &amp; User Centric Flows. Plenty of illustrations, Subtle micro animations functional elements all
            come together to really set a creative mood and submerge the user in the websites special atmosphere.
          </p>
        </div>
        <div className="frame-5">
          <div className="text-wrapper-6">Website + SAAS</div>
          <div className="text-wrapper-7">Fintech</div>
        </div>
        <div className="frame-6">
          <div className="text-wrapper-8">Moneyfactory</div>
          <div className="intelligent">
            Intelligent Investing,
            <br />
            made easy!
          </div>
        </div>
        <div className="group-4">
          <div className="frame-7">
            <div className="UIX">
              <div className="frame-wrapper">
                <div className="frame-8">
                  <div className="group-5">
                    <div className="overlap-group-3">
                      <img className="line" alt="Line" src="/img/line-18-1.svg" />
                      <img className="line-2" alt="Line" src="/img/line-19.svg" />
                    </div>
                  </div>
                  <div className="text-wrapper-9">Branding</div>
                </div>
              </div>
            </div>
            <div className="UIX-2">
              <div className="frame-wrapper">
                <div className="frame-8">
                  <div className="group-5">
                    <div className="overlap-group-3">
                      <img className="line" alt="Line" src="/img/line-18.svg" />
                      <img className="line-2" alt="Line" src="/img/line-19.svg" />
                    </div>
                  </div>
                  <div className="text-wrapper-9">UI/UX</div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-wrapper-10">Scope of Work</div>
        </div>
        <div className="hero">
          <div className="element-wrapper">
            <img className="img-2" alt="Element" src="/img/002-2.png" />
          </div>
        </div>
        <div className="the-challenge">
          <div className="frame-9">
            <div className="frame-10">
              <div className="text-wrapper-11">The Challenge</div>
              <p className="let-s-face-it-many">
                <span className="span">
                  Let’s face it: many people are uncertain about their financial futures. Especially for those who lack
                  the privilege of generational wealth, it can be hard to break into investing. High barriers to entry
                  mixed with abstract concepts and opaque language have created a financial system that thrives on fear
                  and confusion — asking people to surrender control of their money with little transparency in return.{" "}
                  <br />
                  <br />
                </span>
                <span className="text-wrapper-12">
                  Enter Moneyfactory. <br />
                </span>
                <span className="span">
                  <br />
                  Moneyfactory empowers first-time investors to seasoned experts to take control of their finances with
                  AI-Driven Algorithm trading&nbsp;&nbsp;at their fingertips. That allows people to invest in what they
                  believe without any hassle, efficiently and effectively taking them close to their financial goals.{" "}
                  <br />
                  <br />
                  We were invited to help redefine the Moneyfactory’s brand - its expression, the product and the
                  website— so that, as Moneyfactory forges this new future, its brand will match its ambition - Make
                  Investing Ridiculously Smart &amp; easy for anyone and everyone who needs it.
                  <br />
                  <br />
                  Our insight? Don’t just make finance “more smart” Make finance more intelligently automated,
                  seamlessly engaging and understandable in a way unlike anybody else.
                </span>
              </p>
            </div>
            <div className="frame-11">
              <a
                className="text-wrapper-13"
                href="https://www.moneyfactory.ai/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Launch Project
              </a>
            </div>
          </div>
        </div>
        <img className="headshot" alt="Headshot" src="/img/headshot.png" />
        <div className="branding">
          <div className="overlap-6">
            <div className="text-wrapper-14">Carefully Crafted Branding</div>
            <div className="group-wrapper">
              <img className="group-6" alt="Group" src="/img/group-45.png" />
            </div>
            <div className="frame-12">
              <div className="overlap-7">
                <div className="rectangle-4" />
                <div className="mockup">
                  <div className="overlap-8">
                    <div className="body-space-gray">
                      <div className="button-space-gray">
                        <div className="rectangle-5" />
                        <img className="union" alt="Union" src="/img/lines-front.png" />
                      </div>
                      <div className="button-space-gray-2">
                        <div className="rectangle-6" />
                        <img className="union-2" alt="Union" src="/img/lines-front.png" />
                      </div>
                      <div className="button-space-gray-3">
                        <div className="rectangle-7" />
                        <img className="union-3" alt="Union" src="/img/lines-front.png" />
                      </div>
                      <div className="button-space-gray-4">
                        <div className="rectangle-7" />
                        <img className="union-4" alt="Union" src="/img/lines-front.png" />
                      </div>
                      <div className="overlap-group-4">
                        <img className="shine" alt="Shine" src="/img/shine.png" />
                        <img className="outer-ring" alt="Outer ring" src="/img/outer-ring.svg" />
                        <img className="bands" alt="Bands" src="/img/bands.svg" />
                      </div>
                    </div>
                    <img className="base" alt="Base" src="/img/base.svg" />
                    <div className="display">
                      <img className="mask-group-3" alt="Mask group" src="/img/mask-group-1.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="img-wrapper">
              <img className="frame-13" alt="Frame" src="/img/frame-1880.svg" />
            </div>
            <p className="p">
              Our first task was to give a makeover to Moneyfactory’s logotype and corporate identity.
            </p>
            <p className="text-wrapper-15">
              The illustration style went through several phases and multiple iterations.
            </p>
            <p className="versatile-responsive">Versatile &amp; Responsive that it fits even in smaller spaces</p>
            <p className="text-wrapper-16">
              Spruced up and cleaned up the lettering with custom created logotype that’s based on the brand Typefaces
            </p>
            <div className="frame-14">
              <div className="overlap-9">
                <img className="frame-15" alt="Frame" src="/img/frame-33-3.svg" />
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-wrapper-2">
          <div className="overlap-10">
            <div className="rectangle-8" />
            <img className="mackbooks" alt="Mackbooks" src="/img/mackbooks-1.png" />
            <div className="rectangle-9" />
            <img className="group-7" alt="Group" src="/img/group-1780.png" />
          </div>
        </div>
        <div className="overlap-wrapper-3">
          <div className="overlap-11">
            <div className="rectangle-8" />
            <div className="overlap-group-wrapper-3">
              <div className="overlap-group-5">
                <img className="frame-16" alt="Frame" src="/img/frame-1764.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-wrapper-4">
          <div className="overlap-12">
            <div className="rectangle-10" />
            <div className="rectangle-11" />
            <img className="img-3" alt="Element" src="/img/0003-1.png" />
            <img className="basic-office" alt="Basic office" src="/img/basic-office-stationery-set-mockup-1.png" />
          </div>
        </div>
        <div className="ios-notification-wrapper">
          <div className="ios-notification">
            <div className="header-2">
              <div className="overlap-13">
                <div className="header-3">
                  <div className="overlap-group-6">
                    <div className="group-8" />
                  </div>
                  <div className="text-wrapper-17">Moneyfactory</div>
                  <div className="text-wrapper-18">5m ago</div>
                </div>
                <img className="logo-2" alt="Logo" src="/img/logo-2.svg" />
              </div>
            </div>
            <div className="meta-data">
              <div className="title-desc-pic">
                <div className="title-desc">
                  <div className="div-wrapper-2">
                    <div className="title">Portfolio Adjusted</div>
                  </div>
                  <div className="description">
                    <div className="frame-17">
                      <div className="description-2">Asian Paints position exited.</div>
                      <div className="frame-18">
                        <img className="image" alt="Image" src="/img/image.svg" />
                        <div className="description-2">Profit Earned: 700.36</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="roadie" />
              </div>
              <div className="div-wrapper-2">
                <div className="text-wrapper-19">2 more notifcations</div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-19">
          <p className="text-wrapper-20">Extra attention to detail development</p>
          <p className="we-focused-on-every">
            We focused on every little detail including animation, seamless UX flows to really enhance the overall
            experience with engaging content &amp;&nbsp;&nbsp;playful user interactions.
          </p>
        </div>
        <div className="web-wrapper">
          <div className="web">
            <div className="overlap-14">
              <div className="BG" />
              <img className="group-9" alt="Group" src="/img/group-1970.svg" />
              <div className="logo-instance-wrapper">
                <Logo
                  className="logo-3"
                  color="black"
                  colorBlackSize="/img/logo-1.svg"
                  size="default"
                  type="logotype"
                />
              </div>
              <div className="context">
                <div className="title-2">
                  <div className="text-wrapper-21">Invest like the 5%</div>
                  <p className="text-wrapper-22">
                    Until today, Only few have had access to be profitable and invest right with stock investing. We are
                    here to change that!
                  </p>
                </div>
                <div className="CTA-2">
                  <button className="button">
                    <div className="text-wrapper-23">Apply for early access</div>
                  </button>
                  <p className="coming-soon-on-web">
                    <span className="text-wrapper-24"> Coming Soon on </span>
                    <span className="text-wrapper-25">Web</span>
                    <span className="text-wrapper-24">, </span>
                    <span className="text-wrapper-25">Android</span>
                    <span className="text-wrapper-24"> and </span>
                    <span className="text-wrapper-25">iOS</span>
                  </p>
                </div>
              </div>
              <img className="hero-image" alt="Hero image" src="/img/hero-image.png" />
              <div className="feature">
                <img className="image-2" alt="Image" src="/img/image-2.png" />
                <div className="context-2">
                  <p className="text-wrapper-26">The smartest investing assistant in town</p>
                  <p className="text-wrapper-27">
                    There’s no need to ever make a manual trade. Our robots will rebalance your portfolio, automatically
                    diversify deposits, enter and exit to keep your portfolio profitable, all while you sitback <br />
                    and relax.
                  </p>
                </div>
              </div>
              <div className="feature-2">
                <div className="content">
                  <button className="CTA-3">
                    <div className="text-wrapper-28">Get Started</div>
                  </button>
                  <div className="content-2">
                    <div className="text-wrapper-29">Investing made ridiculously easy</div>
                    <p className="text-wrapper-30">
                      Investing isn’t easy. We just make it feel that way. Get started in minutes, pick one or couple of
                      our propreitary investment strategies and start your pofitable investing journey.
                    </p>
                  </div>
                </div>
                <img className="image-3" alt="Image" src="/img/image-1.png" />
              </div>
              <div className="group-10">
                <div className="content-3">
                  <div className="efficient-investing">
                    Efficient Investing, <br />
                    brilliantly personalized
                  </div>
                  <p className="text-wrapper-27">
                    Just answer a few questions, and we’ll give you a personalized portfolio of wonderfully diversified,
                    stocks designed to weather the market and grow your money.
                  </p>
                </div>
                <img className="image-4" alt="Image" src="/img/image-2.svg" />
              </div>
            </div>
            <div className="frame-20">
              <div className="frame-21">
                <p className="text-wrapper-31">One shot, three ways to make them</p>
                <div className="frame-22">
                  <div className="simulated">
                    <div className="frame-23">
                      <div className="text-wrapper-32">Simulated</div>
                      <p className="text-wrapper-33">
                        Test your ablities, learn how to trade better, backtest in seconds, and deploy smartly with our
                        AI-Driven Platform
                      </p>
                    </div>
                    <img className="frame-24" alt="Frame" src="/img/frame-33-2.svg" />
                  </div>
                  <div className="assisted">
                    <div className="overlap-group-7">
                      <div className="frame-23">
                        <div className="text-wrapper-34">Assisted</div>
                        <p className="text-wrapper-35">
                          Get notified instantly about profitable trades, invest your capital, sitback and let the
                          profits come to you.
                        </p>
                      </div>
                      <img className="frame-24" alt="Frame" src="/img/frame-33-1.svg" />
                    </div>
                  </div>
                  <div className="automatic">
                    <div className="frame-25">
                      <div className="text-wrapper-36">Automatic</div>
                      <p className="text-wrapper-37">
                        Let our AI, take control of your portfolio, adjust your risk, add capital and let our robots
                        take you towards exponential profits
                      </p>
                    </div>
                    <img className="frame-24" alt="Frame" src="/img/frame-33.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="group-11">
              <div className="frame-26">
                <div className="group-12">
                  <div className="group-13">
                    <div className="frame-27">
                      <div className="text-wrapper-38">Join the Revolution!</div>
                      <div className="apply-for-all-access">
                        <div className="CTA-4" />
                        <div className="join">
                          <div className="text-wrapper-39">Join the Waitlist</div>
                        </div>
                        <div className="text-wrapper-40">Email Address</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-28">
                    <div className="group-14">
                      <div className="text-wrapper-41">Queries? Talk to us</div>
                    </div>
                    <div className="support-moneyfactory-wrapper">
                      <a href="mailto:support@moneyfactory.ai" rel="noopener noreferrer" target="_blank">
                        <img
                          className="support-moneyfactory"
                          alt="Support moneyfactory"
                          src="/img/support-moneyfactory-ai.png"
                        />
                      </a>
                    </div>
                  </div>
                  <img className="line-3" alt="Line" src="/img/line-39.svg" />
                  <div className="frame-29">
                    <Logo
                      className="logo-4"
                      color="white"
                      colorWhiteSize="/img/logo.svg"
                      size="default"
                      type="logotype"
                    />
                    <p className="finaptis-consulting">
                      Finaptis Consulting Private Limited,
                      <br /> No 87/1, Coles Road, Frazer Town,
                      <br />
                      Bangalore India 560005
                      <br />
                      <br />
                      <br />
                      Contact Us: 080 40997076
                    </p>
                  </div>
                  <div className="instagram-linkedin">
                    Instagram&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LinkedIn&nbsp;&nbsp;&nbsp;&nbsp;
                    Twitter&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Facebook
                  </div>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="group-15">
                <div className="frame-30">
                  <p className="text-wrapper-42">Exclusive Invites for the very few!</p>
                  <p className="text-wrapper-43">For those who are, be prepared for an experience unlike no other.</p>
                </div>
                <div className="group-16">
                  <div className="frame-31">
                    <div className="text-wrapper-44">Tell us about you</div>
                    <p className="text-wrapper-45">We’ll tell a lot more about us</p>
                  </div>
                  <div className="frame-32">
                    <div className="text-wrapper-44">Login</div>
                    <p className="text-wrapper-46">No need for complex signups, just login with your broker account</p>
                  </div>
                  <div className="frame-33">
                    <div className="text-wrapper-44">Subscribe</div>
                    <p className="text-wrapper-47">To our recommended Strategies and start minting profits!</p>
                  </div>
                  <div className="group-17">
                    <img className="line-4" alt="Line" src="/img/line-4.svg" />
                    <img className="line-5" alt="Line" src="/img/line-4.svg" />
                    <div className="group-18">
                      <div className="overlap-group-8">
                        <div className="text-wrapper-48">1</div>
                      </div>
                    </div>
                    <div className="group-19">
                      <div className="overlap-15">
                        <div className="text-wrapper-49">2</div>
                      </div>
                    </div>
                    <div className="group-20">
                      <div className="overlap-group-8">
                        <div className="text-wrapper-50">3</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-34">
                <div className="content-4">
                  <p className="works-only-with-the">
                    Works only with the best
                    <br />
                    and most trusted financial brands
                  </p>
                  <p className="text-wrapper-51">
                    All investments through Moneyfactory is done through your existing demat account. Get started with
                    your brokerage account without any hassle
                  </p>
                </div>
                <img className="brokers" alt="Brokers" src="/img/brokers.svg" />
              </div>
              <div className="about-section">
                <div className="group-21">
                  <div className="text-wrapper-52">About Us</div>
                  <p className="text-wrapper-53">
                    We are a new-age fintech with a mission to close the gaps between the world of investing and you,
                    also to reimagine and deliver smart investing experiences for anyone and everyone.
                  </p>
                </div>
                <div className="frame-35">
                  <p className="text-wrapper-54">Proudly Made In India For The World!</p>
                  <div className="frame-36">
                    <div className="frame-37">
                      <div className="frame-38">
                        <div className="DIPP-recognized">DIPP RECOGNIZED STARTUP</div>
                        <img className="image-5" alt="Image" src="/img/image-6.svg" />
                        <img className="image-6" alt="Image" src="/img/image-8.svg" />
                        <img className="image-7" alt="Image" src="/img/image-9.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="website" alt="Website" src="/img/website.png" />
        <div className="group-22">
          <div className="overlap-16">
            <img className="logo-5" alt="Logo" src="/img/logo.png" />
          </div>
        </div>
        <div className="frame-39">
          <div className="text-wrapper-55">The Result</div>
          <p className="text-wrapper-56">
            The result: a boldly evolved, future-focused brand that balances a high-performance product experience with
            a more engaging and inspiring creative expression. A Moneyfactory that encourages customers to imagine
            better futures and helps to build them.
          </p>
        </div>
      </div>
    </div>
  );
};
