/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Logo = ({
  color,
  size,
  type,
  className,
  colorWhiteSize = "/img/color-white-size-default-type-logotype.svg",
  colorBlackSize = "/img/color-black-size-default-type-logotype.svg",
}) => {
  return (
    <img
      className={`logo ${size} ${type} ${className}`}
      alt="Color white size"
      src={
        size === "default" && color === "white"
          ? colorWhiteSize
          : color === "black" && size === "default" && type === "logotype"
          ? colorBlackSize
          : color === "white" && size === "small"
          ? "/img/color-white-size-small-type-logotype.svg"
          : color === "black" && size === "small"
          ? "/img/color-black-size-small-type-logotype.svg"
          : color === "default"
          ? "/img/color-default-size-default-type-icon.svg"
          : color === "black" && type === "icon"
          ? "/img/color-black-size-default-type-icon.svg"
          : undefined
      }
    />
  );
};

Logo.propTypes = {
  color: PropTypes.oneOf(["black", "white", "default"]),
  size: PropTypes.oneOf(["small", "default"]),
  type: PropTypes.oneOf(["logotype", "icon"]),
  colorWhiteSize: PropTypes.string,
  colorBlackSize: PropTypes.string,
};
